import React, { useState } from 'react';
import { CloseIcon, HamburgerIcon } from '../../Atoms/Icons/Icons';
import SidebarItems from './SidebarItems/SidebarItems';

function Sidebar() {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  return (
    <div
      className={`lg:border-b-none sticky top-0 z-50 flex w-full flex-grow-0 flex-col border-b border-b-greyDark bg-white p-4 lg:z-0 lg:h-screen lg:max-w-[300px] lg:border-r-2 lg:pt-16 ${
        mobileMenuActive ? 'h-screen' : ''
      }`}
    >
      <div
        className="flex items-center justify-between
      lg:border-b-0"
      >
        <div className="lg:hidden">
          <button onClick={() => setMobileMenuActive(!mobileMenuActive)}>
            {mobileMenuActive ? <CloseIcon /> : <HamburgerIcon />}
          </button>
        </div>
      </div>

      <SidebarItems
        mobileMenuActive={mobileMenuActive}
        setMobileMenuActive={setMobileMenuActive}
      />
    </div>
  );
}

export default Sidebar;
