// Dependencies
import Icon from '@mdi/react';
import { mdiSortVariant } from '@mdi/js';

const FilterBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className='relative w-full bg-marble flex flex-row flex-wrap items-center'>
      <div className='pl-6 lg:pl-10 py-3 pr-6 flex h-fit whitespace-nowrap items-center bg-titanium'>
        <Icon path={mdiSortVariant} size='1.5rem' className='inline mr-2' />
        <span>Filters</span>
      </div>

      <ul
        className='lg:left-0 flex w-auto bg-inherit lg:top-0 divide-x divide-x-titanium lg:relative'>
        {children}
      </ul>
    </div>
  );
};

export default FilterBar;
