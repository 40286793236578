import React from 'react';

import { NoResultProps } from './NoResult.model';

import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

function NoResult({ variant = 'Dark' }: NoResultProps) {
  return (
    <div
      className={`${
        variant === 'Dark' ? ' bg-darkGrey' : 'bg-limestone'
      } flex items-center py-8 lg:py-14`}
    >
      <div className={`mx-auto max-w-2xl px-4`}>
        <div className="flex justify-center">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            xmlns="http://www.w3.org/2000/svg"
            className={`mb-5 ${
              variant !== 'Dark' ? ' fill-darkGrey' : 'fill-white'
            }`}
          >
            <path d="M55.7135 22.1266V17.8409C55.7135 17.448 55.392 17.1266 54.9992 17.1266H20.7134C20.3206 17.1266 19.9992 17.448 19.9992 17.8409V22.1266C19.9992 22.5195 20.3206 22.8409 20.7134 22.8409H54.9992C55.392 22.8409 55.7135 22.5195 55.7135 22.1266ZM20.7134 29.9837C20.3206 29.9837 19.9992 30.3052 19.9992 30.698V34.9837C19.9992 35.3766 20.3206 35.698 20.7134 35.698H37.142C37.5349 35.698 37.8563 35.3766 37.8563 34.9837V30.698C37.8563 30.3052 37.5349 29.9837 37.142 29.9837H20.7134ZM33.5706 70.3409H12.8563V7.48375H62.8563V38.198C62.8563 38.5909 63.1777 38.9123 63.5706 38.9123H68.5706C68.9635 38.9123 69.2849 38.5909 69.2849 38.198V3.91232C69.2849 2.33196 68.0081 1.05518 66.4277 1.05518H9.28488C7.70452 1.05518 6.42773 2.33196 6.42773 3.91232V73.9123C6.42773 75.4927 7.70452 76.7695 9.28488 76.7695H33.5706C33.9634 76.7695 34.2849 76.448 34.2849 76.0552V71.0552C34.2849 70.6623 33.9634 70.3409 33.5706 70.3409ZM73.3652 74.9391L65.0349 66.6087C67.026 63.9748 68.2135 60.6891 68.2135 57.1266C68.2135 48.448 61.1777 41.4123 52.4992 41.4123C43.8206 41.4123 36.7849 48.448 36.7849 57.1266C36.7849 65.8052 43.8206 72.8409 52.4992 72.8409C55.6956 72.8409 58.6599 71.8855 61.142 70.2516L69.5974 78.707C69.7402 78.8498 69.9188 78.9123 70.0974 78.9123C70.2759 78.9123 70.4634 78.8409 70.5974 78.707L73.3652 75.9391C73.4311 75.8736 73.4834 75.7956 73.519 75.7098C73.5547 75.624 73.5731 75.532 73.5731 75.4391C73.5731 75.3462 73.5547 75.2542 73.519 75.1684C73.4834 75.0826 73.4311 75.0046 73.3652 74.9391ZM52.4992 67.1266C46.9724 67.1266 42.4992 62.6534 42.4992 57.1266C42.4992 51.5998 46.9724 47.1266 52.4992 47.1266C58.026 47.1266 62.4992 51.5998 62.4992 57.1266C62.4992 62.6534 58.026 67.1266 52.4992 67.1266Z" />
          </svg>
        </div>
        <Heading
          level="h4"
          className={`mb-2 text-center ${
            variant !== 'Dark' ? ' text-darkGrey' : 'text-white'
          }`}
        >
          <strong className="font-medium">Unavailable graph results!</strong>
        </Heading>
        <Paragraph
          variant="xl"
          className={`mb-4 text-center font-light ${
            variant !== 'Dark' ? ' text-darkGrey' : 'text-white'
          }`}
        >
          Unfortunately based on the filters you have selected, we couldn't find
          any results. Please select another filter and try again.
        </Paragraph>
      </div>
    </div>
  );
}

export default NoResult;
