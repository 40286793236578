import React, { memo, useEffect, useState } from 'react';

import NoResult from '../NoResult/NoResult';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutChartProps } from './DoughnutChart.model';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

ChartJS.register(ArcElement, Tooltip, Legend);
const DoughnutChart = memo(
  ({
    chartTitle,
    chartDescription,
    data,
    className = '',
  }: DoughnutChartProps) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
      const total = data.reduce((acc, data) => acc + data.count, 0);
      setTotal(total);
    }, [data]);

    return (
      <section className={`p-4 lg:px-12 lg:py-8 ${className}`}>
        <div className="flex w-full flex-row flex-wrap items-start justify-between">
          <div className="w-full max-w-475">
            {chartTitle && (
              <Heading level="h2" className="mb-2">
                {chartTitle}
              </Heading>
            )}
            {chartDescription && (
              <Paragraph variant="sm" className="mb-6 lg:mb-8">
                {chartDescription}
              </Paragraph>
            )}
          </div>
        </div>

        {data.length > 0 ? (
          <div className="relative mx-auto max-w-xl">
            <Doughnut
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    labels: {
                      textAlign: 'left',
                      usePointStyle: true,
                      pointStyle: 'circle',
                    },
                  },
                },
              }}
              data={{
                labels: data.map((r) => r.label),
                datasets: [
                  {
                    data: data.map((r) => Math.round((r.count / total) * 100)),
                    backgroundColor: [
                      'rgba(28, 70, 255, 1)',
                      'rgba(28, 70, 255, 0.9)',
                      'rgba(28, 70, 255, 0.8)',
                      'rgba(28, 70, 255, 0.7)',
                      'rgba(28, 70, 255, 0.6)',
                      'rgba(28, 70, 255, 0.5)',
                      'rgba(28, 70, 255, 0.4)',
                      'rgba(28, 70, 255, 0.3)',
                      'rgba(28, 70, 255, 0.2)',
                      'rgba(28, 70, 255, 0.1)',
                      // 'rgba(2, 33, 53, 1)',
                      // 'rgba(2, 33, 53, 0.9)',
                      // 'rgba(2, 33, 53, 0.8)',
                      // 'rgba(2, 33, 53, 0.7)',
                      // 'rgba(2, 33, 53, 0.6)',
                      // 'rgba(2, 33, 53, 0.5)',
                      // 'rgba(2, 33, 53, 0.4)',
                      // 'rgba(2, 33, 53, 0.3)',
                      // 'rgba(2, 33, 53, 0.2)',
                      // 'rgba(2, 33, 53, 0.1)',
                    ],
                  },
                ],
              }}
            />
          </div>
        ) : (
          <NoResult />
        )}
      </section>
    );
  }
);
DoughnutChart.displayName = 'DoughnutChart';
export default DoughnutChart;
