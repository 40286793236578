import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

export const FilterWrapper: React.FC<React.PropsWithChildren> = ({
                                                                   children
                                                                 }) => {
  return (
    <li className='relative w-full lg:w-auto'>
        <span className='pointer-events-none absolute right-1 z-10 top-1/2 -translate-y-1/2'>
          <Icon path={mdiChevronDown} size='1.5rem' className='fill-current' />
        </span>
      {children}
    </li>
  );
};
