import React from 'react';
import { LogoProps } from './Logo.model';
function Logo({ topBorder }: LogoProps) {
  return (
    <div>
      <svg
        width="166"
        height="71"
        viewBox="0 0 217 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 22.291V92.9957H43.6245V88.4648H4.86079V22.291H0Z"
          fill="#121212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M95.2848 22.291L66.5845 92.9957H72.0799L98.0938 28.0713L124.107 92.9957H129.725L101.391 22.291H95.2848Z"
          fill="#121212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M195.073 61.2333C192.556 57.9739 189.428 56.0267 185.694 55.3895C188.535 54.9122 190.849 53.0843 192.637 49.9029C194.505 46.7241 195.439 42.9468 195.439 38.5729C195.439 33.9626 193.895 29.8824 190.811 26.543C187.643 23.2837 183.867 21.5493 179.482 21.5493H150.486V26.6698H178.507C181.836 26.6698 184.677 27.7318 187.033 30.1169C189.388 32.5021 190.566 35.2991 190.566 38.6384C190.566 42.7735 189.388 46.2555 187.033 49.1961C184.677 52.1386 181.836 53.5521 178.507 53.5521H150.486V58.0324H178.507C182.729 58.0324 186.344 59.4542 189.349 62.3161C192.351 65.1005 193.855 68.5133 193.855 72.5683C193.855 76.7837 192.351 80.4815 189.349 83.5019C186.344 86.5247 182.729 88.1151 178.507 88.1151H168.137V92.5954H179.482C184.759 92.5954 189.307 90.5906 193.125 86.694C196.859 82.7994 198.729 78.0785 198.729 72.592C198.729 68.2985 197.51 64.4946 195.073 61.2333Z"
          fill="#121212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M200.462 9.53425V13.5186H203.646C204.846 13.5186 205.678 12.6774 205.678 11.5707C205.678 10.3755 204.846 9.53425 203.646 9.53425H200.462ZM202.586 14.8026H200.462V19.0524H199.08V8.29465H203.646C205.586 8.29465 207.106 9.57862 207.106 11.5707C207.106 13.9614 204.892 14.7582 204.247 14.7582L207.247 19.0524H205.494L202.586 14.8026ZM194.234 13.6515C194.234 18.1228 198.063 21.8415 202.771 21.8415C207.478 21.8415 211.307 18.1228 211.307 13.6515C211.307 9.09147 207.478 5.46136 202.771 5.46136C198.063 5.46136 194.234 9.09147 194.234 13.6515ZM192.988 13.6515C192.988 8.42757 197.37 4.26611 202.771 4.26611C208.215 4.26611 212.553 8.42757 212.553 13.6515C212.553 18.8311 208.215 23.0368 202.771 23.0368C197.37 23.0368 192.988 18.8311 192.988 13.6515Z"
          fill="#121212"
        />
      </svg>
    </div>
  );
}

export default Logo;
