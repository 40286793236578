export const formatMoney = (
  value?: number,
  valueIn: 'Pounds' | 'Pence' = 'Pounds'
) => {
  const gbp = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  if (!value) return gbp.format(0);
  if (valueIn === 'Pence') return gbp.format(value / 100);
  return gbp.format(value);
};
