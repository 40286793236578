import React, { useEffect, useState } from 'react';
import {
  ProfileIcon,
  ResultsIcon,
  SettingsIcon,
} from '../../../Atoms/Icons/Icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import SidebarItem from '../../../Atoms/SidebarItem/SidebarItem';
import { SidebarItemsProps } from './SidebarItems.model';
import Logout from '../../Logout/Logout';
import { getMyProjects } from 'src/services/getProjects/getMyProjects';
import checkClaims from 'src/helpers/checkClaims/checkClaims';

function SidebarItems({
  mobileMenuActive,
  setMobileMenuActive,
}: SidebarItemsProps) {
  const router = useLocation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [showProjects, setShowProjects] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);

  useEffect(() => {
    const makeRequestsAsync = async () => {
      const options = {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: '',
      };
      const accessToken = await getAccessTokenSilently(options);

      getMyProjects(accessToken, 'Support').then((projects) => {
        if (projects.length > 0) {
          setShowSupport(true);
        }
      });
      getMyProjects(accessToken, 'Project').then((projects) => {
        if (projects.length > 0) {
          setShowProjects(true);
        }
      });
    };
    makeRequestsAsync();
  }, [getAccessTokenSilently]);

  return (
    <div
      className={`${
        mobileMenuActive ? 'flex w-full bg-white px-4' : 'hidden lg:flex'
      }  left-0 top-8 flex-1 flex-col`}
    >
      <ul className="divide-y divide-titanium lg:mt-10">
        {showSupport && (
          <SidebarItem
            key="/supportreports"
            to="/supportreports"
            icon={
              <ResultsIcon active={router.pathname === '/projectreports'} />
            }
            title="Support Reports"
            onClick={() => setMobileMenuActive(false)}
            variant="link"
          />
        )}
        {showProjects && (
          <SidebarItem
            key="/projectreports"
            to="/projectreports"
            icon={
              <ResultsIcon active={router.pathname === '/projectreports'} />
            }
            title="Project Reports"
            onClick={() => setMobileMenuActive(false)}
            variant="link"
          />
        )}
      </ul>
      <ul className="mt-auto divide-y divide-titanium">
        <SidebarItem
          icon={<ProfileIcon />}
          title={`${user?.name}`}
          onClick={() => setMobileMenuActive(false)}
          variant="button"
        />
        <SidebarItem
          to="/my-account"
          icon={<SettingsIcon active={router.pathname === '/my-account'} />}
          onClick={() => setMobileMenuActive(false)}
          title="Account Settings"
          variant="link"
        />
        {checkClaims(user, 'Admin') && (
          <SidebarItem
            to="/users"
            icon={<SettingsIcon active={router.pathname === '/my-account'} />}
            title="Manage Users"
            variant="link"
          />
        )}
        <Logout />
      </ul>
    </div>
  );
}

export default SidebarItems;
