// Dependencies
import React, { useState, useEffect } from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';

// Models
import { useAuth0 } from '@auth0/auth0-react';
import UserListing from 'src/components/Organisms/UserListing/UserListing';
import { useNavigate } from 'react-router-dom';
import { User } from 'src/services/getUsers/Users.model';
import { getUsers } from 'src/services/getUsers/getUsers';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';

function UsersListing() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      try {
        const options = {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        };
        const accessToken = await getAccessTokenSilently(options);

        setUsers(await getUsers(accessToken));
        setLoading(false);
      } catch (e) {
        alert('Authentication Error FB');
      }
    };
    makeRequestsAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer bgColor="bg-marble" button={false} title="Manage Users">
      <div className="divide-y-1 grid grid-cols-1 gap-8 p-4 lg:px-12 lg:py-8">
        {loading && (
          <div>
            <LoadingIcon />
          </div>
        )}
        {!loading && (
          <UserListing
            data={users.map((u) => {
              return {
                userId: u.userId,
                email: u.email,
                name: u.name,
                lastLogin: u.lastLogin ? new Date(u.lastLogin) : null,
              };
            })}
            onSelectUser={(email) => navigate('/users/' + email)}
          />
        )}
      </div>
    </PageContainer>
  );
}

export default UsersListing;
