export const formatMinutesAsTime = (
  totalMinutes?: number,
  dashIfZero: boolean = false
): string => {
  if (!totalMinutes && dashIfZero) return '-';
  if (!totalMinutes) return '00:00';
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const hoursString = hours < 10 ? '0' + hours : hours;
  const minutesString = minutes < 10 ? '0' + minutes : minutes;
  return hoursString + ':' + minutesString;
};
