import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';
import checkClaims from 'src/helpers/checkClaims/checkClaims';

interface props {
  role?: string;
}

function PrivateRoute(props: props) {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  }

  if (isAuthenticated && (!props.role || checkClaims(user, props.role))) {
    return <Outlet />;
  }
  return <Navigate to="/" />;
}
export default PrivateRoute;
