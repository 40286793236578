import { api } from '../axiosInstance';
import { ProjectWithStatus } from './Projects.model';

export const getProjectsWithStatus = async (
  token: string,
  userId: string
): Promise<ProjectWithStatus[]> => {
  const res = await api.get('allprojectswithstatus?userId=' + userId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
