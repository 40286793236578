import { api } from '../axiosInstance';
import { Deal } from './ProductiveDeal.model';

export const getProductiveDeals = async (
  projectId: string,
  token: string
): Promise<Deal[]> => {
  const res = await api.get('productiveDeals?projectId=' + projectId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
