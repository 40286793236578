import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';
export const LoginRoute = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  }
  if (isAuthenticated) return <Navigate to="/supportreports" />;
  return <Outlet />;
};
