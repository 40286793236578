import React from 'react';
import { LogoutIcon } from '../../Atoms/Icons/Icons';
import SidebarItem from '../../Atoms/SidebarItem/SidebarItem';
import useModal from '../../hooks/useModal';
import LogoutModal from '../LogoutModal/LogoutModal';

function Logout() {
  const { isOpen, toggleModal } = useModal();
  return (
    <>
      <SidebarItem
        icon={<LogoutIcon active={isOpen} />}
        title="Log Out"
        variant="button"
        onClick={toggleModal}
      />
      <LogoutModal isLogoutModalOpen={isOpen} toggleLogoutModal={toggleModal} />
    </>
  );
}

export default Logout;
