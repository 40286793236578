// Dependencies
import React, { useState, useEffect, useCallback } from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';
import DoughnutChart from '../../Organisms/DoughnutChart/DoughnutChart';
import FilterBar from '../../Organisms/FilterBar/FilterBar';

// Models
import { useAuth0 } from '@auth0/auth0-react';
import { getProductiveTimeEntries } from '../../../services/getProductiveTimeEntries/getProductiveTimeEntries';
import { TimeEntry } from '../../../services/getProductiveTimeEntries/ProductiveTimeEntry.model';
import TimeByJiraTicketTable from 'src/components/Organisms/TimeByJiraTicketTable/TimeByJiraTicketTable';
import { Listbox } from '@headlessui/react';
import { FilterWrapper } from 'src/components/Molecules/Filter/FilterWrapper';
import { Project } from 'src/services/getProjects/Projects.model';
import { Deal } from 'src/services/getProductiveDeals/ProductiveDeal.model';
import { getMyProjects } from 'src/services/getProjects/getMyProjects';
import { getProductiveDeals } from 'src/services/getProductiveDeals/getProductiveDeals';
import { formatMinutesAsTime } from 'src/helpers/formatMinutesAsTime/formatMinutesAsTime';
import { formatDateAsMonthNameAndYear } from 'src/helpers/formatDate/formatDate';
import SummaryItem from 'src/components/Atoms/SummaryResulsItem/SummaryItem';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';

function SupportReports() {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [deals, setDeals] = useState<Deal[]>();
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [timeEntryResponses, setTimeEntryResponses] = useState<TimeEntry[]>([]);

  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      try {
        const options = {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        };
        const accessToken = await getAccessTokenSilently(options);

        getMyProjects(accessToken, 'Support')
          .then((projects) => {
            setProjects(projects);

            // Default to first project
            if (projects.length > 0) {
              // Default to first project
              setSelectedProject(projects[0]);
            }
          })
          .catch(console.error);
      } catch (e) {
        alert('Authentication Error FB');
      }
    };
    makeRequestsAsync().catch((e) => {
      console.log(e);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh Deals list when selected project changes
  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      if (!selectedProject) {
        return;
      }

      try {
        console.log('Get deals');
        const options = {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        };
        const accessToken = await getAccessTokenSilently(options);

        getProductiveDeals(selectedProject.id, accessToken)
          .then((deals) => {
            // Check for no deals
            if (deals.length === 0) {
              setSelectedDeal(null);
              return;
            }

            // Sort deals newest to oldest
            deals = deals.sort((a, b) => {
              if (a.date < b.date) return 1;
              if (a.date > b.date) return -1;
              return 0;
            });
            setDeals(deals);

            // Default to first deal
            setSelectedDeal(deals[0]);
          })
          .catch(console.error);
      } catch (e) {
        alert('Authentication Error FB');
      }
    };
    makeRequestsAsync().catch((e) => {
      console.log(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  // Refresh time entries when selected deal changes
  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      if (selectedDeal) {
        try {
          console.log('Get time entries');
          const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: '',
          });

          getProductiveTimeEntries(selectedDeal.dealId, accessToken)
            .then((response) => {
              setTimeEntryResponses(response);
              setLoading(false);
            })
            .catch(console.error);
        } catch (e) {
          alert('Authentication Error QR');
        }
      } else {
        if (timeEntryResponses.length > 0) setTimeEntryResponses([]);
        setLoading(false);
      }
    };
    makeRequestsAsync().catch((e) => {
      console.log(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeal]);

  const getTicketTimeUsed = useCallback(() => {
    return timeEntryResponses.map((value) => {
      return {
        label: value.jiraIssueId ? value.jiraIssueId : 'Unknown',
        count: value.totalBillableTime,
      };
    });
  }, [timeEntryResponses]);

  const getTicketTimeUsedDetaild = useCallback(() => {
    return timeEntryResponses.map((value) => {
      return {
        jiraTicketId: value.jiraIssueId ? value.jiraIssueId : 'Unknown',
        billableTime: value.totalBillableTime,
        summary: value.jiraIssueId ? value.summary : '-',
        status: value.jiraIssueId ? value.status : '-',
      };
    });
  }, [timeEntryResponses]);

  return (
    <PageContainer
      button={false}
      title="Support Reports"
      filterBar={
        <FilterBar>
          {projects && projects.length > 1 && (
            <FilterWrapper>
              <Listbox value={selectedProject} onChange={setSelectedProject}>
                {({ open }) => (
                  <>
                    <Listbox.Button className="... block truncate whitespace-nowrap py-3 pl-6 pr-8 text-left lg:min-w-40">
                      {selectedProject?.name}
                    </Listbox.Button>
                    {open && (
                      <div className="absolute top-full left-0 w-full border-t border-t-titanium bg-marble text-left drop-shadow-sm">
                        <Listbox.Options>
                          {projects.map((project) => (
                            <Listbox.Option
                              key={project.id}
                              value={project}
                              className="cursor-pointer p-3 py-2 pl-6 hover:bg-titanium"
                            >
                              {project.name}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    )}
                  </>
                )}
              </Listbox>
            </FilterWrapper>
          )}
          <FilterWrapper>
            <Listbox value={selectedDeal} onChange={setSelectedDeal}>
              {({ open }) => (
                <>
                  <Listbox.Button className="... block truncate whitespace-nowrap py-3 pl-6 pr-8 text-left lg:min-w-40">
                    {selectedDeal
                      ? formatDateAsMonthNameAndYear(selectedDeal.date)
                      : ''}
                  </Listbox.Button>
                  {open && (
                    <div className="absolute top-full left-0 h-60 w-full overflow-y-auto border-t border-t-titanium bg-marble text-left drop-shadow-sm">
                      <Listbox.Options>
                        {deals?.map((deal) => (
                          <Listbox.Option
                            key={deal.dealId}
                            value={deal}
                            className="cursor-pointer p-3 py-2 pl-4 hover:bg-titanium"
                          >
                            {formatDateAsMonthNameAndYear(deal.date)}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  )}
                </>
              )}
            </Listbox>
          </FilterWrapper>
        </FilterBar>
      }
    >
      {loading && (
        <div>
          <LoadingIcon />
        </div>
      )}
      {!loading && (
        <>
          {selectedDeal && (
            <section className="mb-8 grid w-full grid-cols-2 p-4 lg:grid-cols-5 lg:gap-3 lg:p-6 2xl:gap-4">
              <SummaryItem
                description={'Period'}
                value={formatDateAsMonthNameAndYear(selectedDeal.date)}
                suffix={''}
                theme="Neutral"
              />
              <SummaryItem
                description={'Monthly Allowance'}
                value={formatMinutesAsTime(selectedDeal.budgetedTime)}
                suffix={''}
                theme="Neutral"
              />
              <SummaryItem
                description={'Adjusted Time'}
                value={formatMinutesAsTime(selectedDeal.estimatedTime)}
                suffix={''}
                theme="Neutral"
              />
              <SummaryItem
                description={'Current Usage'}
                value={formatMinutesAsTime(selectedDeal.billableTime)}
                suffix={''}
                theme={
                  selectedDeal.billableTime > selectedDeal.estimatedTime
                    ? 'Negative'
                    : 'Positive'
                }
              />
            </section>
          )}
          {/* Pie Chart */}
          <DoughnutChart
            className=""
            data={getTicketTimeUsed()}
            chartTitle=""
            chartDescription=""
          />
          <TimeByJiraTicketTable
            data={getTicketTimeUsedDetaild()}
            title=""
            description=""
          />
        </>
      )}
    </PageContainer>
  );
}

export default SupportReports;
