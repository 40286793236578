import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {SidebarItemProps} from './SidebarItem.model';
import {RightArrow} from '../Icons/Icons';

function SidebarItem({
                       to,
                       title,
                       icon,
                       variant,
                       activeNavButton,
                       onClick,
                     }: SidebarItemProps) {
  const location = useLocation();
  return (
    <div>
      <li className="hover:bg-marble">
        {variant === 'link' && (
          <Link
            to={to as string}
            onClick={onClick}
            className="flex items-center gap-3 py-3 px-6 group"
          >
            {icon}
            {title}
            {location.pathname === to && (
              <RightArrow
                className="ml-auto fill-current text-turquoise"
                fill="current"
              />
            )}
          </Link>
        )}
        {variant === 'button' && (
          <button
            onClick={onClick}
            className="flex w-full items-center gap-3 py-3 px-6 group"
          >
            {icon}
            {title}
            {activeNavButton && (
              <RightArrow className="ml-auto fill-current text-turquoise"/>
            )}
          </button>
        )}
      </li>
    </div>
  );
}

export default SidebarItem;
