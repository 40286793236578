import { api } from '../axiosInstance';
import { ProjectBudgetPlan } from './ProjectBudgetPlan.model';

export const getProjectBudgetPlan = async (
  projectId: string,
  token: string
): Promise<ProjectBudgetPlan[]> => {
  const res = await api.get('projectBudgetPlan?projectId=' + projectId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
