// Dependencies
import React from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';

function RenderTest() {
  console.log('Render Test');

  return (
    <PageContainer button={false} title="Reports">
      Test
    </PageContainer>
  );
}

export default RenderTest;
