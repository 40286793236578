export type ArrowDirections = 'Left' | 'Right' | 'None';

export interface ButtonProps extends React.PropsWithChildren {
  className?: string;
  to?: string;
  arrowDirection?: ArrowDirections;
  variant?: keyof typeof variants;
  disabled?: boolean;
  type: 'submit' | 'button' | 'reset';
  onClick?: () => void;
}

export interface Variants {
  primary: string;
  secondary: string;
  tertiary: string;
  basic: string;
}

export const variants: Variants = Object.freeze({
  primary:
    'py-4 px-6 bg-blue hover:bg-blue active:bg-blue rounded text-white font-bold transition-colors',
  secondary:
    'py-4 px-6 border border-topaz-500 hover:border-topaz-700 active:border-topaz-700 text-topaz-500 hover:text-topaz-700 active:text-topaz-700 rounded font-bold transition-colors',
  tertiary:
    'py-4 px-6 text-darkGrey hover:text-charcoal active:text-jet font-bold transition-colors',
  basic:
    'py-4 text-topaz-500 hover:text-topaz-700 active:text-topaz-700 font-normal underline transition-colors',
});

export const disabledVariants: Variants = Object.freeze({
  primary: 'py-4 px-6 bg-titanium rounded text-darkGrey font-bold',
  secondary: 'py-4 px-6 bg-titanium rounded text-darkGrey font-bold',
  tertiary: 'py-4 px-6 bg-titanium rounded text-darkGrey font-bold',
  basic: 'py-4 text-titanium',
});
