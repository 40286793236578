import { Switch } from '@headlessui/react';
import { Heading } from 'src/components/Atoms/Heading/Heading';
import { UserProjectsProps } from './UserProjects.model';

function UserProjects({ Projects, onToggleProject }: UserProjectsProps) {
  return (
    <section className="w-full overflow-hidden p-4 lg:px-12 lg:py-8">
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full max-w-475">
          <Heading level="h2" className="mb-2">
            Projects
          </Heading>
        </div>
      </div>
      {Projects.length > 0 && (
        <div className="w-full max-w-full overflow-hidden">
          <div className="relative w-auto max-w-full overflow-auto">
            <table className="w-full table-auto font-normal text-gray-500">
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  ></th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-white">
                {Projects.map((project) => {
                  return (
                    <tr className="border-b bg-white" key={project.id}>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {project.name}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        <Switch
                          checked={project.status}
                          onChange={(value: boolean) => {
                            onToggleProject && onToggleProject(project);
                          }}
                          className={`${
                            project.status ? 'bg-blue-600' : 'bg-gray-200'
                          } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${
                              project.status ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
}

export default UserProjects;
