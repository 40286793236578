// Dependencies
import React, { useState, useEffect, useCallback } from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';
import FilterBar from '../../Organisms/FilterBar/FilterBar';

// Models
import { useAuth0 } from '@auth0/auth0-react';
import { Listbox } from '@headlessui/react';
import { FilterWrapper } from 'src/components/Molecules/Filter/FilterWrapper';
import { Project } from 'src/services/getProjects/Projects.model';
import { getMyProjects } from 'src/services/getProjects/getMyProjects';
import { formatMinutesAsTime } from 'src/helpers/formatMinutesAsTime/formatMinutesAsTime';
import SummaryItem from 'src/components/Atoms/SummaryResulsItem/SummaryItem';
import { getProjectBudgetPlan } from 'src/services/getProjectBudgetPlan/getProjectBudgetPlan';
import {
  ProjectBudgetPlan,
  ServiceWithWeekPlanVsActual,
} from 'src/services/getProjectBudgetPlan/ProjectBudgetPlan.model';
import { Heading } from 'src/components/Atoms/Heading/Heading';
import { formatMoney } from 'src/helpers/formatMoney/formatMoney';
import ResourcePlanTable from 'src/components/Organisms/ResourcePlanTable/ResourcePlanTable';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';
import ServiceTable from 'src/components/Organisms/ServiceTable/ServiceTable';

function ProjectReports() {
  console.log('Render reports');
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectBudgetPlans, setProjectBudgetPlans] = useState<
    ProjectBudgetPlan[]
  >([]);

  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      try {
        console.log('Get projects');
        const options = {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        };
        const accessToken = await getAccessTokenSilently(options);

        getMyProjects(accessToken, 'Project')
          .then((projects) => {
            setProjects(projects);

            // Default to first project
            if (projects.length > 0) {
              // Default to first project
              setSelectedProject(projects[0]);
            }
          })
          .catch(console.error);
      } catch (e) {
        alert('Authentication Error FB');
      }
    };
    makeRequestsAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh project data when selected project changes
  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      if (selectedProject) {
        try {
          console.log('Get project data');
          const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: '',
          });

          getProjectBudgetPlan(selectedProject.id, accessToken)
            .then((response) => {
              setProjectBudgetPlans(response);
              setLoading(false);
            })
            .catch(console.error);
        } catch (e) {
          alert('Authentication Error QR');
        }
      } else {
        if (projectBudgetPlans.length > 0) setProjectBudgetPlans([]);
      }
    };
    makeRequestsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const getWeekPlanVsActualData = useCallback(
    (services: ServiceWithWeekPlanVsActual[]) => {
      return services.map((service) => {
        return {
          name: service.name,
          weekPlanVsActual: service.weekPlanVsActuals,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectBudgetPlans]
  );

  return (
    <PageContainer
      button={false}
      title="Project Reports"
      filterBar={
        <FilterBar>
          <FilterWrapper>
            <Listbox value={selectedProject} onChange={setSelectedProject}>
              {({ open }) => (
                <>
                  <Listbox.Button className="... block truncate whitespace-nowrap py-3 pl-6 pr-8 text-left lg:min-w-64">
                    {selectedProject?.name}
                  </Listbox.Button>
                  {open && (
                    <div className="absolute top-full left-0 w-full border-t border-t-titanium bg-marble text-left drop-shadow-sm">
                      <Listbox.Options>
                        {projects &&
                          projects.map((project) => (
                            <Listbox.Option
                              key={project.id}
                              value={project}
                              className="cursor-pointer p-3 py-2 pl-6 hover:bg-titanium"
                            >
                              {project.name}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </div>
                  )}
                </>
              )}
            </Listbox>
          </FilterWrapper>
        </FilterBar>
      }
    >
      {loading && (
        <div>
          <LoadingIcon />
        </div>
      )}
      {!loading && (
        <>
          {projectBudgetPlans.map((plan) => {
            return (
              <div key={plan.dealId}>
                <section className="w-full overflow-hidden p-4 lg:px-12 lg:py-8">
                  <Heading level="h2" className="mb-6">
                    {plan.name}
                  </Heading>
                  <div className="mb-8 grid w-full grid-cols-2 gap-4 lg:grid-cols-4">
                    <SummaryItem
                      description={'Planned Time'}
                      value={formatMinutesAsTime(plan.budgetedTime)}
                      suffix={''}
                      theme="Neutral"
                    />
                    <SummaryItem
                      description={'Time Used'}
                      value={formatMinutesAsTime(plan.billableTime)}
                      suffix={''}
                      theme="Neutral"
                    />
                    <SummaryItem
                      description={'Budget'}
                      value={formatMoney(plan.budget, 'Pence')}
                      suffix={''}
                      theme="Neutral"
                    />
                    <SummaryItem
                      description={'Budget used'}
                      value={formatMoney(plan.budgetUsed, 'Pence')}
                      suffix={''}
                      theme="Neutral"
                    />
                  </div>
                </section>
                {plan.sections.map((section) => {
                  return (
                    <>
                      {section.services.length > 0 && (
                        <section className="w-full overflow-hidden p-4 lg:px-12 lg:py-8">
                          <Heading level="h3">{section.name}</Heading>

                          <ServiceTable
                            data={section.services}
                            className="lg:py-8"
                          />
                          <ResourcePlanTable
                            data={getWeekPlanVsActualData(section.services)}
                            className="lg:py-8"
                          />
                        </section>
                      )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </PageContainer>
  );
}

export default ProjectReports;
