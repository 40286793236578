import React from 'react';
import { formatMinutesAsTime } from '../../../helpers/formatMinutesAsTime/formatMinutesAsTime';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import NoResult from '../NoResult/NoResult';
import { TimeByJiraTicketTableProps } from './TimeByJiraTicketTable.model';

function TimeByJiraTicketTable({
  title,
  description,
  data,
  onCSVDownload,
}: TimeByJiraTicketTableProps) {
  // Order time from largest to smallest
  data.sort((a, b) => {
    return b.billableTime - a.billableTime;
  });

  return (
    <section className="w-full overflow-hidden p-4 lg:px-12 lg:py-8">
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full max-w-475">
          <Heading level="h2" className="mb-2">
            {title}
          </Heading>
          <Paragraph variant="sm" className="mb-6 lg:mb-8">
            {description}
          </Paragraph>
        </div>
        {onCSVDownload && (
          <Button
            variant="secondary"
            className="w-1/2 lg:w-auto"
            type="button"
            onClick={onCSVDownload}
          >
            Download CSV
          </Button>
        )}
      </div>
      {data.length > 0 ? (
        <div className="w-full max-w-full overflow-hidden">
          <div className="relative w-auto max-w-full overflow-auto">
            <table className="w-full table-auto text-center font-normal text-gray-500">
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Jira Ticket
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Summary
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-white">
                {data.map((row) => {
                  return (
                    <tr className="border-b bg-white" key={row.jiraTicketId}>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {row.jiraTicketId !== 'Unknown' && (
                          <a
                            href={
                              'https://labteam.atlassian.net/browse/' +
                              row.jiraTicketId
                            }
                            target="_blank"
                            className="underline"
                            rel="noreferrer"
                          >
                            {' '}
                            {row.jiraTicketId}
                          </a>
                        )}
                        {row.jiraTicketId === 'Unknown' && 'Unknown'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {row.summary}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {row.status}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {formatMinutesAsTime(row.billableTime)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoResult />
      )}
    </section>
  );
}

export default TimeByJiraTicketTable;
