import React from 'react';
import {PageContainerProps} from './PageContainer.model';

function PageContainer({
                         title,
                         button,
                         children,
                         bgColor = 'bg-white',
                         filterBar,
                       }: PageContainerProps) {
  return (
    <main className="w-full lg:max-w-[calc(100%-300px)]">
      <div className="flex items-center justify-between p-4 lg:p-10">
        <h1 className="text-xl font-medium text-darkBlue">{title}</h1>
        {button && button}
      </div>
      {filterBar && (
        <div className="sticky top-14 z-10 lg:top-0 left-0">{filterBar}</div>
      )}
      <main className={`min-h-screen space-y-6 ${bgColor}`}>
        {children}
      </main>

    </main>
  );
}

export default PageContainer;
