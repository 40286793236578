import { Heading } from 'src/components/Atoms/Heading/Heading';
import NoResult from '../NoResult/NoResult';
import { UserListingProps } from './UserListing.model';
import { formatDateAsDayMonthNameYear } from 'src/helpers/formatDate/formatDate';

function UserListing({ data, onSelectUser }: UserListingProps) {
  data.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  return (
    <section className="w-full overflow-hidden p-4 lg:px-12 lg:py-8">
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full max-w-475">
          <Heading level="h2" className="mb-2">
            Users
          </Heading>
        </div>
      </div>
      {data.length > 0 && (
        <div className="w-full max-w-full overflow-hidden">
          <div className="relative w-auto max-w-full overflow-auto">
            <table className="w-full table-auto text-center font-normal text-gray-500">
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Last Login
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-white">
                {data.map((row) => {
                  return (
                    <tr
                      className="cursor-pointer border-b bg-white"
                      key={row.email}
                      onClick={() => {
                        onSelectUser && onSelectUser(row.userId);
                      }}
                    >
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {row.name}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {row.email}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {row.lastLogin &&
                          formatDateAsDayMonthNameYear(row.lastLogin)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {data.length === 0 && <NoResult />}
    </section>
  );
}

export default UserListing;
