import React from 'react';
import { formatMinutesAsTime } from 'src/helpers/formatMinutesAsTime/formatMinutesAsTime';
import { formatMoney } from 'src/helpers/formatMoney/formatMoney';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import { ServiceTableProps } from './ServiceTable.model';

function ServiceTable({ data, onCSVDownload, className }: ServiceTableProps) {
  return (
    <div className={`${className ? className : ''}`}>
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full max-w-475">
          <Heading level="h4" className="mb-2">
            Services
          </Heading>
        </div>
        {onCSVDownload && (
          <Button
            variant="secondary"
            className="w-1/2 lg:w-auto"
            type="button"
            onClick={onCSVDownload}
          >
            Download CSV
          </Button>
        )}
      </div>
      {data.length > 0 ? (
        <div className="w-full max-w-full overflow-hidden">
          <div className="relative w-auto max-w-full overflow-auto">
            <table className="w-full table-auto text-center font-normal text-gray-500">
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  {' '}
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Service Name
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Time
                    <br />
                    (Used / Planned)
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Day Rate
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Budget
                    <br /> (Used / Total)
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-white">
                {data.map((service) => {
                  return (
                    <tr className="border-b bg-white">
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 ">
                        {service.name === service.serviceType ||
                        service.serviceType === '' ||
                        service.serviceType === null ? (
                          service.name
                        ) : (
                          <>
                            {' '}
                            {service.name} - {service.serviceType}
                          </>
                        )}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 ">
                        {formatMinutesAsTime(service.billableTime)} /{' '}
                        {formatMinutesAsTime(service.budgetedTime)}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 ">
                        {formatMoney(service.dayRate, 'Pence')}
                      </td>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 ">
                        {formatMoney(service.budgetUsed, 'Pence')} /{' '}
                        {formatMoney(service.budget, 'Pence')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>No services.</>
      )}
    </div>
  );
}

export default ServiceTable;
