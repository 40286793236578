import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../../Views/Login/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { LoginRoute } from '../LoginRoute/LoginRoute';
import { routeslist } from './routeslist';

const RouterController = () => {
  return (
    <Routes>
      {routeslist.map(({ path, element: Element, role }) => (
        <Route key={path} path={path} element={<PrivateRoute role={role} />}>
          <Route path={path} element={<Element />} />
        </Route>
      ))}
      <Route element={<LoginRoute />} path="/">
        <Route path="/" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default RouterController;
