import NoMatchRoute from '../../../Views/NoMatchRoute/NoMatchRoute';
import AccountSettings from '../../../Views/AccountSettings/AccountSettings';
import SupportReports from '../../../Views/SupportReport/SupportReports';
import ProjectReports from '../../../Views/ProjectReport/ProjectReports';
import RenderTest from '../../../Views/RenderTest/RenderTest';
import UsersProjects from 'src/components/Views/UsersProjects/UsersProjects';
import UsersListing from 'src/components/Views/Users/UsersListing';

interface IRoute {
  path: string;
  element: () => JSX.Element;
  role?: string;
}

export const routeslist: IRoute[] = [
  {
    path: '/rendertest',
    element: RenderTest,
  },
  {
    path: '/supportreports',
    element: SupportReports,
  },
  {
    path: '/projectreports',
    element: ProjectReports,
  },
  {
    path: '/my-account',
    element: AccountSettings,
  },
  {
    path: '/users',
    element: UsersListing,
    role: 'Admin',
  },
  {
    path: '/users/:userId',
    element: UsersProjects,
    role: 'Admin',
  },
  {
    path: '*',
    element: NoMatchRoute,
  },
];
