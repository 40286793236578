import _ from 'lodash';
import React from 'react';
import { formatMinutesAsTime } from '../../../helpers/formatMinutesAsTime/formatMinutesAsTime';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import {
  ResourcePlanTableProps,
  WeekPlanVsActual,
} from './ResourcePlanTable.model';

function ResourcePlanTable({
  data,
  onCSVDownload,
  className,
}: ResourcePlanTableProps) {
  let periods: string[] = [];
  for (let service of data) {
    periods = _.union(
      periods,
      service.weekPlanVsActual.map((service) => {
        return service.period;
      })
    );
  }

  periods.sort((a, b) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });

  const getTimeForPeriod = (
    period: string,
    data: WeekPlanVsActual[],
    type: 'planned' | 'actual'
  ): number | undefined => {
    if (data.length === 0) return;

    const matchingData = data.filter((value) => {
      return value.period === period;
    });
    if (matchingData.length === 0) return undefined;

    return type === 'planned'
      ? matchingData[0].plannedTime
      : matchingData[0].actualTime;
  };

  return (
    <div className={`${className ? className : ''}`}>
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full max-w-475">
          <Heading level="h4" className="mb-2">
            Week Plan
          </Heading>
        </div>
        {onCSVDownload && (
          <Button
            variant="secondary"
            className="w-1/2 lg:w-auto"
            type="button"
            onClick={onCSVDownload}
          >
            Download CSV
          </Button>
        )}
      </div>
      {data.length > 0 ? (
        <div className="w-full max-w-full overflow-hidden">
          <div className="relative w-auto max-w-full overflow-auto">
            <table className="w-full table-auto text-center font-normal text-gray-500">
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  {' '}
                  <th
                    scope="col"
                    rowSpan={2}
                    className="border border-titanium bg-blue py-6 px-6"
                  >
                    Service
                  </th>
                  {periods.map((period) => {
                    return (
                      <th
                        key={period}
                        scope="col"
                        colSpan={2}
                        className="border border-titanium bg-blue py-6 px-6"
                      >
                        {period}
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  {periods.map((period) => {
                    return (
                      <>
                        <th
                          scope="col"
                          className="border border-titanium bg-blue py-6 px-6"
                        >
                          Planned
                        </th>
                        <th
                          scope="col"
                          className="border border-titanium bg-blue py-6 px-6"
                        >
                          Actual
                        </th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-white">
                {data.map((service) => {
                  return (
                    <tr className="border-b bg-white">
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {service.name}
                      </td>

                      {periods.map((period) => {
                        return (
                          <>
                            <td className="whitespace-nowrap border border-titanium py-6 px-6 ">
                              {formatMinutesAsTime(
                                getTimeForPeriod(
                                  period,
                                  service.weekPlanVsActual,
                                  'planned'
                                ),
                                true
                              )}
                            </td>
                            <td className="border border-titanium py-4 px-6 text-lg font-medium text-gray-900">
                              {formatMinutesAsTime(
                                getTimeForPeriod(
                                  period,
                                  service.weekPlanVsActual,
                                  'actual'
                                ),
                                true
                              )}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>No planned booking or time spent.</>
      )}
    </div>
  );
}

export default ResourcePlanTable;
