import { api } from '../axiosInstance';
import { Project } from './projects.model';

export const setUserProjects = async (
  token: string,
  userId: string,
  projects: Project[]
): Promise<undefined> => {
  const res = await api.post('setuserprojects?userId=' + userId, projects, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
