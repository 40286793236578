import { api } from '../axiosInstance';
import { Project } from './Projects.model';

export const getMyProjects = async (
  token: string,
  type: 'Project' | 'Support'
): Promise<Project[]> => {
  const res = await api.get('myprojects?type=' + type, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
