import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import {LayoutProps} from './Layout.model';
import {useLocation} from 'react-router-dom';

function Layout({children}: LayoutProps) {
  const location = useLocation();
  return (
    <div className="flex min-h-screen flex-col lg:flex-row lg:flex-wrap">
      {location.pathname !== '/' && <Sidebar/>}
      {children}
    </div>
  );
}

export default Layout;
