export const formatDateAsMonthNameAndYear = (dateString: string): string => {
  const date = new Date(dateString);
  const monthName = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return monthName + ' ' + year;
};

export const formatDateAsDayMonthNameYear = (date: Date): string => {
  const day = date.getDate();
  const monthName = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return day + ' ' + monthName + ' ' + year;
};
