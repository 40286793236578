// Dependencies
import React, { useState, useEffect } from 'react';

// Components
import PageContainer from '../../Containers/PageContainer/PageContainer';

// Models
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import UserProjects from 'src/components/Organisms/UserProjects/UserProjects';
import { ProjectWithStatus } from 'src/components/Organisms/UserProjects/UserProjects.model';
import { getProjectsWithStatus } from 'src/services/getProjects/getProjectsWithStatus';
import { LoadingIcon } from 'src/components/Atoms/Icons/Icons';
import { setUserProjects } from 'src/services/setUserProjects/setUserProjects';

function UsersProjects() {
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();
  const [projects, setProjects] = useState<ProjectWithStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const makeRequestsAsync = async () => {
      try {
        if (params.userId) {
          const options = {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: '',
          };
          const accessToken = await getAccessTokenSilently(options);

          setProjects(await getProjectsWithStatus(accessToken, params.userId));
          setLoading(false);
        }
      } catch (e) {
        alert('Authentication Error FB');
      }
    };
    makeRequestsAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  function handleProjectToggle(project: ProjectWithStatus) {
    const projectRecUpdated = {
      ...project,
      status: !project.status,
    };
    const projectDataNew = projects.map(function (rec) {
      return rec.id === project.id ? projectRecUpdated : rec;
    });
    setProjects(projectDataNew);

    const makeRequestsAsync = async () => {
      if (params.userId) {
        const options = {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        };
        const accessToken = await getAccessTokenSilently(options);

        setUserProjects(
          accessToken,
          params.userId,
          projectDataNew
            .filter((x) => {
              return x.status;
            })
            .map((x) => {
              return { id: x.id, name: x.name };
            })
        );
      }
    };
    makeRequestsAsync();
  }

  return (
    <PageContainer bgColor="bg-marble" button={false} title="Manage Users">
      <div className="divide-y-1 grid grid-cols-1 gap-8 p-4 lg:px-12 lg:py-8">
        {loading && (
          <div>
            <LoadingIcon />
          </div>
        )}
        {!loading && (
          <UserProjects
            Projects={projects}
            onToggleProject={(project) => {
              handleProjectToggle(project);
            }}
          />
        )}
      </div>
    </PageContainer>
  );
}

export default UsersProjects;
