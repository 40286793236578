import { api } from '../axiosInstance';
import { TimeEntry } from './ProductiveTimeEntry.model';

export const getProductiveTimeEntries = async (
  dealId: string,
  token: string
): Promise<TimeEntry[]> => {
  const res = await api.get('productiveTimeEntries?dealId=' + dealId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
