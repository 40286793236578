import React from 'react';
import { SummaryItemProps } from './SummaryItem.model';

function SummaryItem({
  description,
  value,
  suffix,
  className,
  theme,
}: SummaryItemProps) {
  if (!className) className = '';
  let themeValueClassName = 'text-darkBlue';
  switch (theme) {
    case 'Negative':
      themeValueClassName = 'text-red';
      break;
    case 'Positive':
      themeValueClassName = 'text-green';
      break;
  }
  return (
    <div
      className={
        'border-titanium py-4 px-3 last:border-r-0 lg:border-r 2xl:px-5 ' +
        className
      }
    >
      <div className="flex flex-col text-center leading-tight">
        <p className="text-sm text-gray-500 lg:text-base">{description}</p>
        <p
          className={
            'summary-results-item-result text-xl ' +
            themeValueClassName +
            ' lg:text-xl 2xl:text-2xl'
          }
        >
          {value}{' '}
          <span className="lg:text-base xl:text-lg 2xl:text-2xl">{suffix}</span>
        </p>
      </div>
    </div>
  );
}

export default SummaryItem;
